export default [
    {
      header: 'Setting',
      resource: 'Setting',
      action: 'read',
      
    },
    {
      title: 'Bts',
      route: 'bts-lists',
      icon: 'SlackIcon',
      resource: 'Setting',
      action: 'read',
     
      tagVariant: 'light-warning',
     
    },
    {
      title: 'Mrt',
      route: 'mrt-lists',
      icon: 'SlackIcon',
      resource: 'Setting',
      action: 'read',
     
      tagVariant: 'light-warning',
     
    },
    {
      title: 'Airport Link',
      route: 'airport-lists',
      icon: 'SlackIcon',
      resource: 'Setting',
      action: 'read',
     
      tagVariant: 'light-warning',
     
    },
    {
      title: 'Zone',
      route: 'zone-lists',
      icon: 'SlackIcon',
      resource: 'Setting',
      action: 'read',
     
      tagVariant: 'light-warning',
     
    },
    {
      title: 'Developer',
      route: 'developer-lists',
      icon: 'SlackIcon',
      resource: 'Setting',
      action: 'read',
     
      tagVariant: 'light-warning',
     
    },
    {
      title: 'Amenities',
      route: 'amenities-lists',
      icon: 'SlackIcon',
      resource: 'Setting',
      action: 'read',
     
      tagVariant: 'light-warning',
    },
    {
      title: 'Facilities',
      route: 'facilities-lists',
      icon: 'SlackIcon',
      resource: 'Setting',
      action: 'read',
     
      tagVariant: 'light-warning',
    },
    
  ]
  