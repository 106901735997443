export default [
    {
      title: 'Property Pin',
      icon: 'MapPinIcon',
     
      tagVariant: 'light-warning',
      resource: 'Place',
      action: 'read',
      children: [
        {
          title: 'Lists',
          route: 'place-lists',
          resource: 'Place',
          action: 'read',
        },
        {
          title: 'Add',
          route: 'pin-create',
          resource: 'Place',
          action: 'create',
        },
      
      ],
    },
  ]
  