export default [
    {
      title: 'Enquiry',
      icon: 'LayoutIcon',
     
      tagVariant: 'light-warning',
      resource: 'Enquiry',
      action: 'read',
      children: [
        {
          title: 'Lists',
          route: 'enquiry-lists',
          resource: 'Enquiry',
          action: 'read',
        },
        {
          title: 'Add',
          route: 'enquiry-add',
          resource: 'Enquiry',
          action: 'create',
        },

       
      
      ],
    },
  ]
  