export default [
    {
      title: 'Storage',
      icon: 'FileTextIcon',
      route: 'files-read',
      tagVariant: 'light-warning',
      resource: 'Property',
        action: 'read',
     
    },
  ]
  