export default [
    {
        title: 'Inventory List',
        icon: 'LayoutIcon',
        tagVariant: 'light-warning',
        resource: 'Inventory List',
        action: 'read',
        children: [
            {
                title: 'Lists',
                route: 'inventory-lists',
                resource: 'Inventory List',
                action: 'read',
            },
            {
                title: 'Add',
                route: 'inventory-units',
                resource: 'Inventory List',
                action: 'read',
            },
            {
                title: 'Room Types',
                route: 'roomType-list',
                resource: 'RoomType List',
                action: 'read',
            },
        ],
    },
]