export default [
    {
      title: 'Phone Book',
      icon: 'MapPinIcon',
     
      tagVariant: 'light-warning',
      resource: 'Phonebook',
      action: 'read',
      children: [
        {
          title: 'Lists',
          route: 'phonebook-lists',
          resource: 'Phonebook',
          action: 'read',
        },
        {
          title: 'Service',
          route: 'phonebook-service-lists',
          resource: 'Phonebook Service',
          action: 'read',
        },
      ],
    },
  ]
  