export default [
  {
    title: 'Property',
    icon: 'HomeIcon',
   
    tagVariant: 'light-warning',
    resource: 'Property',
    action: 'read',
    children: [
      {
        title: 'Lists',
        route: 'property-lists',
        resource: 'Property',
        action: 'read',
      },
      {
        title: 'Add',
        route: 'property-create',
        resource: 'Property',
        action: 'create',
      },
    
    ],
  },
]
