export default [

    {
      title: 'Close Deal',
      icon: 'MapPinIcon',
     
      tagVariant: 'light-warning',
      resource: 'CloseDeal',
      action: 'read',
      children: [
        {
          title: 'Lists',
          route: 'close-deal-lists',
          resource: 'CloseDeal',
          action: 'read',
        },
        {
          title: 'Setting',
          route: 'close-deal-setting',
          resource: 'CloseDeal',
          action: 'read',
        },
      
      ],
    },
  ]
  
  