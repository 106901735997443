export default [
  {
    title: 'Dashboard',
    icon: 'ActivityIcon',
    route: 'dashboard-fbr',
    action: 'read',
    resource: 'Dash',
   
  },
]
