export default [
    {
      header: 'Blogs',
      resource: 'Blog',
      action: 'read',
      
    },
    {
      title: 'Blog',
      icon: 'BookIcon',
      resource: 'Blog',
        action: 'read',
     
      tagVariant: 'light-warning',
      children: [
        {
          title: 'List',
          route: 'blog-lists',
          resource: 'Blog',
           action: 'read',
        },
        {
          title: 'Add',
          route: 'blog-add',
          resource: 'Blog',
          action: 'create',
        },
      
      ],
    },
    {
      title: 'Blog Category',
      icon: 'BookIcon',
      resource: 'Blog',
        action: 'read',
     
      tagVariant: 'light-warning',
      children: [
        {
          title: 'List',
          route: 'blog-category-lists',
          resource: 'Blog',
           action: 'read',
        },
        {
          title: 'Add',
          route: 'blog-category-add',
          resource: 'Blog',
          action: 'create',
        },
      
      ],
    },
    {
      title: 'Knowledge Base',
      icon: 'BookIcon',
      resource: 'Blog',
        action: 'read',
     
      tagVariant: 'light-warning',
      children: [
        {
          title: 'List',
          route: 'knowledgebase-lists',
          resource: 'Blog',
           action: 'read',
        },
        {
          title: 'Add',
          route: 'knowledgebase-add',
          resource: 'Blog',
          action: 'create',
        },
      
      ],
    },


    {
      title: 'Knowledge Base Category',
      icon: 'BookIcon',
      resource: 'Blog',
        action: 'read',
     
      tagVariant: 'light-warning',
      children: [
        {
          title: 'List',
          route: 'knowledgebase-category-lists',
          resource: 'Blog',
           action: 'read',
        },
        {
          title: 'Add',
          route: 'knowledgebase-category-add',
          resource: 'Blog',
          action: 'create',
        },
      
      ],
    },
  ]
  