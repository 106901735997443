export default [
    {
      header: 'Crm',
      resource: 'Crm',
      action: 'read',
    },
     
    {
        title: 'Subscriber',
        route: 'subscriber-list',
        resource: 'Crm',
        icon: 'MailIcon',
        action: 'sendmail',
    },
    {
      title: 'Template',
      route: 'crm-template',
      resource: 'Crm',
      icon: 'FileTextIcon',
      action: 'template',
  },
  {
    title: 'Campaign',
    route: 'crm-campaign',
    resource: 'Crm',
    icon: 'MailIcon',
    action: 'campaign',
},
]
    