export default [
  {
    header: 'Users',
    resource: 'User',
    action: 'read',
  },
  {
    title: 'Customer',
    icon: 'UsersIcon',
    resource: 'User',
    action: 'read',
    children: [
      
      {
        title: 'List',
        route: 'clients-list',
        resource: 'User',
        action: 'read',
      },
      {
        title: 'Add',
        route: 'clients-add',
        resource: 'User',
        action: 'read',
      },
     
      
    ],
  },
  {
    title: 'Admin',
    icon: 'UserIcon',
    resource: 'User-Admin',
    action: 'read',
    children: [
      {
        title: 'List',
        route: 'admin-list',
        resource: 'User-Admin',
        action: 'read',
      },
      {
        title: 'Add',
        route: 'admin-add',
        resource: 'User-Admin',
        action: 'create',
      },
      
     
      
    ],
  },
  {
    title: 'Group Email',
    icon: 'UsersIcon',
    resource: 'GroupEmail',
    action: 'read',
    children: [
      {
        title: 'List',
        route: 'group-email',
        resource: 'GroupEmail',
        action: 'read',
      },
      {
        title: 'Add',
        route: 'group-email-add',
        resource: 'GroupEmail',
        action: 'create',
      },
      
     
      
    ],
  },
  {
    title: 'Agency List',
    icon: 'UsersIcon',
    route: 'agency-list',
    resource: 'agency',
    action: 'read',
    
  },

]
  