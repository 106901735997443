/*

Array of object

Top level object can be:
1. Header
2. Group (Group can have navItems as children)
3. navItem

* Supported Options

/--- Header ---/

header

/--- nav Grp ---/

title
icon (if it's on top level)
tag
tagVariant
children

/--- nav Item ---/

icon (if it's on top level)
title
route: [route_obj/route_name] (I have to resolve name somehow from the route obj)
tag
tagVariant

*/
import dashboard from './dashboard'

import appsAndPages from './apps-and-pages'
import others from './others'
import chartsAndMaps from './charts-and-maps'
import uiElements from './ui-elements'
import formAndTable from './forms-and-table'


import dashboardfbr from './dashboard-fbr'
import property from './property'
import place from './place'
import blog from './blog'
import usr from './usr'
import crm from './crm'
import phonebook from './phonebook'
import enquiry from './enquiry'
import setting from './setting'
import closeDeal from './close-deal'
import files from './files'
import inventoryList from './inventory-list'

// Array of sections
export default [...dashboardfbr, ...closeDeal, ...property, ...place,  ...enquiry, ...inventoryList, ...files, ...usr, ...crm, ...phonebook, ...blog, ...setting]
//export default [...dashboardfbr, ...closeDeal, ...property, ...place,  ...enquiry,...files, ...usr, ...crm, ...phonebook, ...blog, ...setting, ...dashboard, ...appsAndPages, ...uiElements, ...formAndTable, ...chartsAndMaps, ...others]
